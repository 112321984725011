:root {
--rs-color-danger: #F45C4A;
--rs-color-danger-bg: rgba(244,92,74, 0.2);
--rs-color-success: #4DBB5F;
--rs-color-success-bg: rgba(77,187,95, 0.2);
--rs-color-warning: #F3C033;
--rs-color-warning-bg: rgba(243,192,51, 0.2);
--rs-color-primary-light-theme: #6B57FF;
--rs-color-primary-dim-light-theme: #8979ff;
--rs-color-primary-fog-light-theme: #e1ddff;
--rs-color-primary-t-dim-light-theme: rgba(107, 87, 255, 0.8);
--rs-color-primary-t-fog-light-theme: rgba(107, 87, 255,0.2);
--rs-color-primary-dark-theme: #8473FF;
--rs-color-primary-dim-dark-theme: #6f61d2;
--rs-color-primary-fog-dark-theme: #2e2b49;
--rs-color-primary-t-dim-dark-theme: rgba(132, 115, 255,0.8);
--rs-color-primary-t-fog-dark-theme: rgba(132, 115, 255,0.3);
--rs-color-black: #19191C;
--rs-color-white: #FFFFFF;
--rs-color-black-t95: rgba(25,25,28,0.95);
--rs-color-black-t90: rgba(25,25,28,0.9);
--rs-color-black-t80: rgba(25,25,28,0.8);
--rs-color-black-t70: rgba(25,25,28,0.7);
--rs-color-black-t60: rgba(25,25,28,0.6);
--rs-color-black-t50: rgba(25,25,28,0.5);
--rs-color-black-t40: rgba(25,25,28,0.4);
--rs-color-black-t30: rgba(25,25,28,0.3);
--rs-color-black-t20: rgba(25,25,28,0.2);
--rs-color-black-t10: rgba(25,25,28,0.1);
--rs-color-black-t5: rgba(25,25,28,0.05);
--rs-color-white-t5: rgba(255,255,255,0.05);
--rs-color-white-t10: rgba(255,255,255,0.1);
--rs-color-white-t20: rgba(255,255,255,0.2);
--rs-color-white-t30: rgba(255,255,255,0.3);
--rs-color-white-t40: rgba(255,255,255,0.4);
--rs-color-white-t50: rgba(255,255,255,0.5);
--rs-color-white-t60: rgba(255,255,255,0.6);
--rs-color-white-t70: rgba(255,255,255,0.7);
--rs-color-white-t80: rgba(255,255,255,0.8);
--rs-color-white-t90: rgba(255,255,255,0.9);
--rs-color-white-t95: rgba(255,255,255,0.95);
--rs-color-grey-95: #252528;
--rs-color-grey-90: #303033;
--rs-color-grey-80: #474749;
--rs-color-grey-70: #5E5E60;
--rs-color-grey-60: #757577;
--rs-color-grey-50: #8C8C8E;
--rs-color-grey-40: #A3A3A4;
--rs-color-grey-30: #BABABB;
--rs-color-grey-20: #D1D1D2;
--rs-color-grey-10: #E8E8E8;
--rs-color-grey-5: #F4F4F4;
}