@import "npm:modern-normalize/modern-normalize.css";
@import "npm:@rescui/typography/lib/font-jb-sans-auto.css";
@import "npm:@rescui/colors/lib/index.css";

html {
    height: 100%;
}

body {
    background-color: var(--rs-color-grey-10);
    font-family: 'JetBrains Sans', Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
}

img, video {
    display: block;
    max-width: 100%;
    height: auto;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.screen {
    display: block flow-root;
    min-height: 100vh;
}

.container {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 1rem
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-col {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.gap-1 {
    gap: 0.25rem;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-4 {
    gap: 1rem;
}

.w-full {
    width: 100%;
}

.m-0 {
    margin: 0;
}

.mb-4 {
    margin-bottom: 1em;
}

.mb-8 {
    margin-bottom: 2em;
}

.my-8 {
    margin-bottom: 2em;
    margin-top: 2em;
}

.p-2 {
    padding: 0.5em;
}

.cursor-pointer {
    cursor: pointer;
}

.space {
    margin: 10px;
}

.logo {
    height: 4rem;
}

.token {
    width: 424px;
    margin: auto;
}

.error {
    color: red;
    font-weight: bold;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
